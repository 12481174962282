import React, { useEffect, useRef } from "react";
import { GlobalConfig } from "../config";
import axios from "axios";
import spinner_icon from "../../assets/spinner.svg";
import { useSessionDataContext, useSetSessionDataForKeyContext } from "../contexts/sessioncontext";

export default function MainPage() {
    const sessionData = useSessionDataContext();
    const setSessionDataForKey = useSetSessionDataForKeyContext();

    // const [scanResult, setScanResult] = React.useState({
    //     brand: "Credit Swisse",
    //     weight: "1Kg",
    //     fineness: "9999",
    //     bar_nr: "12345678",
    // });
    const [scanResult, setScanResult] = React.useState({
        brand: "",
        weight: "",
        fineness: "",
        bar_nr: "",
    });

    const [captureMode, setCaptureMode] = React.useState(true);
    const [waitMode, setWaitMode] = React.useState(false);
    const [screenMode, setScreenMode] = React.useState(0);

    // Video feed
    //
    let videoRef = useRef(null);
    let renderCanvasRef = useRef(null);
    let front = false;
    let constraint = {
        video: {
            width: { min: 1024, ideal: 1280, max: 1920 },
            height: { min: 576, ideal: 720, max: 1080 },
            facingMode: front ? "user" : "environment",
        },
        audio: false,
    };
    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia(constraint)
            .then((stream) => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.setAttribute("autoplay", "");
                video.setAttribute("muted", "");
                video.setAttribute("playsinline", "");
                video.play();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getFrame = () => {
        let video = videoRef.current;
        let width = video.videoWidth;
        let height = video.videoHeight;
        let render_canvas = renderCanvasRef.current;
        render_canvas.width = width;
        render_canvas.height = height;
        let render_canvas_context = render_canvas.getContext("2d");
        render_canvas_context.drawImage(video, 0, 0, width, height);
        return render_canvas.toDataURL("image/jpeg");
    };

    function handleOnChangeInputText(event) {
        setScanResult((prevScanResult) => {
            return {
                ...prevScanResult,
                [event.target.name]: event.target.value,
            };
        });
    }

    function handleScanOk(event) {
        event.preventDefault();
        setScreenMode(0);
    }

    function handleCapture(event) {
        setScreenMode(1);
        let frame = getFrame();
        frame = frame.replace("data:image/jpeg;base64,", "");
        let data = new FormData();
        data.append("image", frame);
        data.append("session_id", sessionData.sessionID);
        const options = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
            },
        };
        const url = GlobalConfig.API_URL + "/api/upload_image";
        console.log("Requesting: " + url);
        console.log(data);
        axios
            .post(url, data, options)
            .then((res) => {
                console.log(res);
                setScanResult((prevScanResult) => {
                    return { ...prevScanResult, brand: res.data.data.brand };
                });
                setScanResult((prevScanResult) => {
                    return { ...prevScanResult, weight: res.data.data.weight };
                });
                setScanResult((prevScanResult) => {
                    return { ...prevScanResult, fineness: res.data.data.fineness };
                });
                setScanResult((prevScanResult) => {
                    return { ...prevScanResult, bar_nr: res.data.data.bar_nr };
                });
                if (res.status == 200 && res.data.success == true) {
                    setSessionDataForKey("imageFilenameSrc", res.data.data.image_filename_src);
                    setSessionDataForKey("imageFilenameDst", "");
                    // setSessionDataForKey("dialog_msg", res.data.data.msg);
                    // setSessionDataForKey("dialog_button", true);
                    console.log(res.data.data.msg);
                }
                setScreenMode(2);
            })
            .catch((error) => {
                console.log(error.message);
                if (error.message == "Network error") {
                    setSessionDataForKey("dialog_msg", error.message + " Maximum image size: 8Mb.");
                } else {
                    setSessionDataForKey("dialog_msg", error.message);
                }
                setSessionDataForKey("dialog_button", true);
                setSessionDataForKey("imageFilenameSrc", "");
                setSessionDataForKey("imageFilenameDst", "");
                setScreenMode(0);
            });
    }

    useEffect(() => {
        getVideo();
    }, [videoRef]);

    // <div className="video-container">
    //     <video ref={videoRef} className="video-canvas"></video>
    // </div>
    // <div className="overlay"></div>


    return (
        <div>
            <div className="video-container">
                <video ref={videoRef} className="video-canvas"></video>
            </div>
            <div className="photo-container" style={{ visibility: (screenMode === 0) ? "hidden" : "visible" }}>
                <canvas ref={renderCanvasRef} className="photo-canvas"></canvas>
            </div>
            {screenMode == 0 && 
                <div>
                    <div className="darker-20"></div>
                    <div className="info-panel">
                        <button onClick={handleCapture} className="button">
                            Capture
                        </button>
                    </div>
                </div>
            }
            {screenMode == 1 && 
                <div className="spinner-container">
                    <div className="spinner">
                        {" "}
                        <img src={spinner_icon} />
                    </div>{" "}
                </div>
            }
            {screenMode == 2 && 
                <div>
                    <div className="darker-40-blur"></div>
                    <div className="info-panel">
                        <input
                            id="brand"
                            placeholder="Brand"
                            name="brand"
                            type="text"
                            onChange={handleOnChangeInputText}
                            defaultValue={scanResult.brand}
                        ></input>
                        <input
                            id="weight"
                            placeholder="Weight"
                            name="weight"
                            type="text"
                            onChange={handleOnChangeInputText}
                            defaultValue={scanResult.weight}
                        ></input>
                        <input
                            id="fineness"
                            placeholder="Fineness"
                            name="fineness"
                            type="text"
                            onChange={handleOnChangeInputText}
                            defaultValue={scanResult.fineness}
                        ></input>
                        <input
                            id="bar_nr"
                            placeholder="Number"
                            name="bar_nr"
                            type="text"
                            onChange={handleOnChangeInputText}
                            defaultValue={scanResult.bar_nr}
                        ></input>
                        <button onClick={handleScanOk} className="button">
                            Ok
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}

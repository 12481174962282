import React from "react";
import { GlobalConfig } from "../config";

const SessionDataContext = React.createContext();
const setSessionDataForKeyContext = React.createContext();

export function useSessionDataContext() {
    return React.useContext(SessionDataContext);
}

export function useSetSessionDataForKeyContext() {
    return React.useContext(setSessionDataForKeyContext);
}

export function SessionContextProvider({ children }) {
    localStorage.setItem("appName", "AVA-X :: aXedras");

    const [sessionData, setSessionData] = React.useState({
        sessionID: localStorage.getItem("sessionID") || "",
        backendStatus: "offline",
        imageFilenameSrc: "",
        imageFilenameDst: "",
        dialog_msg: "",
        dialog_button: true,
        image_fit: "contain",
        is_minimized: false,
    });

    function setSessionDataForKey(key, value) {
        setSessionData((prevSessionData) => ({
            ...prevSessionData,
            [key]: value,
        }));
    }

    //-----------------------------------------------------------------------------
    // Check backend status
    //-----------------------------------------------------------------------------
    const fetchBackEndStatus = async () => {
        try {
            const response = await fetch(GlobalConfig.API_URL + "/api/status", {
                mode: "cors",
            });
            const response_json = await response.json();
            if (response_json) {
                setSessionDataForKey(
                    "backendStatus",
                    response_json.data.status,
                );
            }
        } catch (error) {
            setSessionDataForKey("backendStatus", error.message);
            setSessionDataForKey("dialog_msg", error.message );
            setSessionDataForKey("dialog_button", true);
            console.log(error.message);
        }
    };
    React.useEffect(() => {
        fetchBackEndStatus();
        // Destructor function
        return () => {};
    }, []);
    //-----------------------------------------------------------------------------
    // Get session ID
    //-----------------------------------------------------------------------------
    const fetchSessionID = async () => {
        try {
            const response = await fetch(
                GlobalConfig.API_URL + "/api/session_id",
                {
                    mode: "cors",
                },
            );
            const response_json = await response.json();
            if (response_json) {
                setSessionDataForKey(
                    "sessionID",
                    response_json.data.session_id,
                );
                localStorage.setItem(
                    "sessionID",
                    response_json.data.session_id,
                );
            }
            console.log({ response_json });
        } catch (error) {
            setSessionDataForKey("sessionID", "");
            setSessionDataForKey("dialog_msg", error.message);
            setSessionDataForKey("dialog_button", true);
            console.log(error.message);
        }
    };
    React.useEffect(() => {
        if (sessionData.sessionID == "") {
            fetchSessionID();
        }
        // Destructor function
        return () => {};
    }, []);

    return (
        <SessionDataContext.Provider value={sessionData}>
            <setSessionDataForKeyContext.Provider value={setSessionDataForKey}>
                {children}
            </setSessionDataForKeyContext.Provider>
        </SessionDataContext.Provider>
    );
}

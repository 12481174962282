import React from "react";
import MainPage from "./components/mainpage"; 
import { SessionContextProvider } from "./contexts/sessioncontext";

export default function App() {

    return (
        <div>
            <React.StrictMode>
                <SessionContextProvider>
                    <MainPage />
                </SessionContextProvider>
            </React.StrictMode>
        </div>
    );
}
